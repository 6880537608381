.about-us-v1 {
	margin-top: var(--page-margin-top);

	/*	.inner-banner {
		background-image: url(RESOURCE/img/bg-wir.jpg);
	}  */

	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;
	}



	.member-info {
		text-align: center;
		background-color: var(--color-white);

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}


}

.facility-bl {
	.img-content {
		display: block;
		height: 300px;

		img {
			width: 100%;
			height: 100%;
			-o-object-fit: cover;
			object-fit: cover;
		}
	}

	.region-bl p {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 10;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}